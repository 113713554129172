@import '../app/vars.scss';

.clip {
	position: relative;
	height: 100vh;
	// background: $black;
	&__video {
		position: relative;
		width: 100%;
		margin-top: calc(50vh - 50vw);
        text-align: center;
        
		@media only screen and (orientation: landscape) {
            height: 100vh;
            width: 100vh;
            margin-top: 0;
            margin-left: calc(50vw - 50vh);
            overflow: hidden;
		}
		background: black;
	}

	.subtitle {
		left: 16px;
		right: 16px;
		text-align: center;
        position: fixed;
        bottom: 4px;
        
        margin: 0 16px;
        padding: 8px 0;
        color: $white;
        background: $black;
		@media only screen and (orientation: landscape) {
            background: none;
            position: absolute;
            display: inline-block;
            bottom: 24px;
            margin: 0 auto;
		}
	}
	.clip__player {
        width: 100%;
        @media only screen and (orientation: landscape) {
            width: 100vh;
        }
	}
}
