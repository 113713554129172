@import '../app/vars.scss';

.app .app-content .mute-warning {
	background: $white;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	color: $black;
	@media screen and (orientation: landscape) and (min-width: 600px) {
		position: relative;
		width: 600px;
		top: 0;
		left: calc(50vw - 300px);
		background-color: #fffdf8;
	}
	&__icon {
		display: block;
		width: 80px;
		height: 80px;
		margin-top: 24px;
	}
}
