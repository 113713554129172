@import '../app/vars.scss';

.pause-menu {
	background: rgba(0, 0, 0, 0.94);
	z-index: 1;
	display: flex;
    flex-flow: row nowrap;
    overflow-x:hidden;

	&--dark {
		background: rgba(255, 253, 248, 0.8);
		.pause-menu {
			&__items, &__overlay, &__overlay td {
				color: $white;
				background: $black;
			}
			&__button {
				border-color: $white;
			}
			&__logo {
				fill: $white;
				color: $white;
			}
			&__close {
				fill: $black;
			}
		}

		&.pause-menu .share {
			border-top: 1px solid $white;
		}
	}
	&__page {
		position: relative;
		flex: 0 0 100vw;
		height: 100%;
		width: 100%;
        display: inline-flex;
        flex-flow: column nowrap;
        align-items: center;
        &--center {
            @media screen and (min-height: 580px) {
                justify-content: center;
            }
        }
	}

	&__overlay {
        box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.4);
        margin: 48px 32px;
        padding: 32px;
        max-width: 480px;
        font-family: Info;
        text-transform: none;

        @media screen and (max-width:400px) {
            margin: 48px 4px;
            max-width: 96vw;
            font-size: 14px;
        }

        &--closed {
            max-height: calc(100vh - 48px);
            overflow: hidden;
        }
    }

    &__title {
        font-family: Subtitle;
        margin-bottom: 1em;
        font-size: 26px;
        text-transform: uppercase;
    }

    &__person {
        margin-top: 28px;
        &:first-child {
            margin-top: 0;
        }
    }

	&__close {
		position: absolute;
		right: 0;
		top: -36px;
		width: 32px;
		height: 32px;
        fill: $white;
        cursor: pointer;
		&:hover {
			fill: $blue;
		}
	}
	&__items,
	&__overlay {
        position: relative;
		background: $white;
		color: $black;
	}
	&__items {
        padding: 16px 4px 8px;
		font-weight: bold;
        font-size: 30px;
        margin-top: 48px;
        @media screen and (min-height: 580px) {
            margin-top: 0;
        }
	}
	&__button {
		cursor: pointer;
		$height: 52px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		padding: 4px 30px;
		height: $height;
		text-align: center;
		border-top: 1px solid $black;
		white-space: nowrap;

		&:first-child {
			border-top: none;
		}

		&:hover {
			color: $blue;
		}
	}

	&__opt {
		font-weight: normal;
	}
	&__strike {
		text-decoration: line-through;
	}

	&__logos {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin: 12px 8px 0;
	}
	&__logo {
		fill: $black;
		color: $black;
		svg {
			height: 36px;
		}

		&--eo svg {
			height: 28px;
			margin: 4px 0;
		}
	}

	.share {
		border-top: 1px solid $black;
    }

    td {
        vertical-align: top;
    }
}
