@import '../app/vars.scss';

.clip-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $white;
    height: 4px;
    text-align: left;
    border-top: 1px solid $black;
    
    @media screen and (orientation:landscape) {
        width: 100vh;
    }
    &__bar {
        display: inline-block;
        background: $blue;
        height: 4px;
    }
}