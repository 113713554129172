@import '../../../node_modules/modern-normalize/modern-normalize.css';
@import 'vars';

button {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
    inset: none;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0.6rem 0;
    width: 100%;
}

h1 {
    padding: 50px 0;
    font-size: 40px;
}

a, a:hover, a:visited, a:active {
    text-decoration: none;
   // color: inherit;
}

.app {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    //flex-direction: column;
    background: $bg;
    
    font-family: 'GothamCondensed', sans-serif;
    font-size: 22px;
    font-weight: normal;
    text-transform: uppercase;
    color: $black;
    
    .page {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $white;
    }

    .bg {
        // background: $bg;
        background-size: cover;
        background-position: center;
    }
}
.fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
