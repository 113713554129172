@import '../app/vars.scss';

.app .app-content .instructions {
	display: inline-flex;
	flex: 1 1 10px;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	text-align: stretch;
	padding: ($headerHeight) 48px 16px;
	align-self: center;
	max-width: 600px;
	left: auto;
	margin: 0 auto;

	&__intro {
        position: relative;
		flex: 1 0 auto;
        display: inline-flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
		padding-bottom: 60px;
		margin: 0;
		min-width: 300px;
		@media screen and (max-width: 400px) {
			font-size: 16px;
			padding-bottom: 8px;
        }

        @media screen and (orientation: landscape) {
            flex: 0 0 auto;
        }

	}

	&__start {
		position: absolute;
		top: 0;
		left: 50%;
		cursor: pointer;
		z-index: 1;
		transform: translate(-45%, -50%);

		@media screen and (max-width: 400px) {
			top: 10%;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__start-link {
		display: block;
		width: 200px;
		height: 98px;
		color: $white;
		font-size: 64px;
		line-height: 92px;
		font-weight: bold;
		text-align: left;
		padding-left: 32px;
		z-index: 1;
		transition: transform 150ms;
		background-size: contain;

		@media screen and (max-width: 400px) {
			padding-left: 24px;
			width: 130px;
			height: 63px;
			font-size: 40px;
			line-height: 60px;
		}
		&:hover {
			transform: scale(1.06);
		}
	}

	&__teaser {
		position: relative;
		flex: 2 1 100px;
		width: 100%;
	}

	&__video {
		top: 0;
		display: inline-block;
		width: 100%;
		@media screen and (orientation: landscape) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__play {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		transition: transform 300ms;
        fill: $blue;
		cursor: pointer;

		&:hover {
			transform: translate3d(-50%, -50%, 0) scale3d(1.5, 1.5, 1);
		}
	}

	&__logos {
        position: relative;
        flex: 0 0 42px;
		display: flex;
		width: 100%;
		min-width: 300px;
		max-width: 600px;
		align-items: flex-end;
		flex-flow: row nowrap;
		justify-content: space-between;
        margin: 12px 8px 0;
	}

	&__logo {
		margin: 0 16px;
		fill: #000000;
		color: #000000;
		svg {
			height: 42px;
		}

		&--eo svg {
			height: 32px;
			margin: 5px 0;
		}
	}

	.subtitle {
        bottom: 8px;
        width: 80%;
        font-size: 14px;
        color: $black;
        margin-left: 10%;

		@media screen and (orientation: landscape) {
            position: absolute;
            // left: 10%;
            color: white;
			/* offset-x | offset-y | blur-radius | color */
			text-shadow: 1px 1px 2px black;
		}
	}
}
