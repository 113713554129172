.share {
	font-weight: normal;
	padding: 0 16px 8px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	& .share__mobile-only {
		@media only screen and (min-width: 768px) {
			display: none;
		}
	}

	> * {
		vertical-align: middle;
	}
	img {
		width: 42px;
	}

	ul {
		list-style: none;
        padding: 0;
        margin: 0;
		white-space: nowrap;
	}
	li {
		display: inline-block;
		padding: 0;
		margin: 0;
		&:first-of-type {
			margin-left: 8px;
		}
	}
	a {
		display: block;
		padding: 0;
		margin: 0;
		transition: transform 150ms;
		&:hover {
			transform: scale(1.1);
		}
	}
	span {
		display: none;
	}
}
