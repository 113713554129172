@import '../app/vars.scss';

.radial-progress {
	display: block;
	position: relative;
	fill: none;
	transform: rotate(-90deg);

	&__background {
        stroke: $black;
	}
    
	&__fill {
        stroke-linecap: butt;
        stroke: $white;
	}
}