@import '../app/vars.scss';

.header {
    width: 100%;
    height: $headerHeight;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    fill: $black;

    &--light {
        fill: $white;
    }

    &__menu-button,
    &__mute-button,
    &__fullscreen-button {
        flex: 0 0 56px;
        padding: 0 0 0 14px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        > div { width: 100%}
    }
    &__mute-button {
        margin-right: 14px;
    }
    &__spacer {
        flex: 0 100 70px;
    }

    &__logo {
        flex: 1 1 192px;
        height: 100%;
        padding: 10px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
