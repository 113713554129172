@import '../app/vars.scss';

.app-content {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	align-items: stretch;
	background: $white;

	@media screen and (orientation: landscape), screen and (min-width: 600px) {
		&__left,
		&__right {
			position: fixed;
			bottom: 70px;
			height: 100%;
			object-fit: cover;
			background-repeat: no-repeat;
			background-size: cover;
			width: calc(50vw - 300px);
		}
		&__left {
            left: 0;
            background-position: bottom right;
		}
		&__right {
            right: 0;
            background-position: bottom left;
		}
	}

	.app-content__transition-wrapper {
		// position: absolute;
		display: flex;
		flex: 1 0 10px;
		flex-direction: column;
		align-items: stretch;
		white-space: normal;
		vertical-align: top;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
        bottom: 0;
        &--chapter .app-content {
            &__left,
            &__right {
                width: calc(50vw - 50vh);
            }
        }
	}

	.story-progress {
		width: 80%;
		left: 10%;
		bottom: 0;
	}

	h1,
	h2,
	h3 {
		text-transform: uppercase;
		font-family: serif;
	}
}
