@import '../app/vars.scss';

.pause {
	background: rgba(0, 0, 0, 0.6);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: $white;
	fill: $white;

	&__center {
		white-space: nowrap;
	}
	&__play,
	&__rewind {
		cursor: pointer;
		transition: transform 300ms;
		&:hover {
			transform: scale(1.1);
		}
	}
	&__play {
		display: inline-block;
		vertical-align: middle;
		margin: 0;

		$size: 140px;
		position: relative;
		width: $size;
		height: $size;
		stroke: $blue;
		stroke-width: 12px;

		@media screen and (max-width: 400px) {
			margin: -12px;
		}
	}
	&__rewind {
		$size: 80px;
		position: relative;
		width: $size;
		height: $size;
		line-height: $size;
		display: inline-block;
		vertical-align: middle;
		font-weight: bold;
		// color: $white;
	}
	&__rewind-arrow {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	&__info {
		vertical-align: middle;
		// text-align: left;
	}
	&__actor {
		font-weight: bold;
		font-size: 42px;
	}
	&__story {
		white-space: normal;
		font-size: 26px;
		margin: 0 8px;

		@media screen and (max-width: 400px) {
			font-size: 20px;
		}
	}
	&__narator {
		@media screen and (max-width: 400px) {
			display: none;
		}
	}
	&__subs {
		position: relative;
		width: 92px;
		margin: 8px 12px 0;
		transition: fill 300ms;
		color: $black;
		cursor: pointer;
		&--active {
			fill: $blue;
			color: $white;
		}
	}
	&__subs-label {
		position: absolute;
		top: 7px;
		width: 100%;
		text-align: center;
		font-size: 16px;
		line-height: 1;
	}
	&__other {
		position: absolute;
		top: $headerHeight - 10px;
		left: 10px;
		width: 84px;
		height: 60px;
		background-size: contain;
		white-space: pre;
		text-align: right;
		padding: 0px 24px 14px 0;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 1;
		font-weight: bold;
		transition: transform 300ms;
		cursor: pointer;
		&:hover {
			transform: scale(1.1) rotate(2deg);
		}
	}

	&__chain {
		margin-top: 24px;
		height: 0;
		padding: 0 40px;
		width: 100%;
		max-width: 360px;
		&--bottom {
			height: auto;
			position: absolute;
			bottom: 0;
			margin: 0 auto;
		}
    }
    
    &__bronnen {
        font-size: 16px;
    }

	.knoppen {
		cursor: pointer;
		> span {
			margin: 0 2px;
			padding: 4px;
			background: grey;
		}
	}
}
