@import '../app/vars.scss';

.choice {
    display: flex;
    padding-top: $headerHeight;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.8);
	user-select: none;

	&-wrapper {
		overflow: hidden;
	}
	&__timer {
		margin: 0 auto;
		color: white;
	}
	&__question {
		font-size: 1.6em;
		margin: 0 0 12px;
		text-align: center;
		font-weight: bold;
		color: $white;
	}
	&__options {
		text-align: center;
        margin: 0 auto;
        padding: 4px;
        // display: flex;
        // flex-flow: column nowrap;
        @media only screen and (orientation:landscape) and (min-width: 1120px) {
            flex-flow: row nowrap;
            white-space: nowrap;
            .choice__option, .choice__target {
                white-space: normal;
                display: inline-flex;
                width: 360px;
                vertical-align: middle;
            }
        }
	}
	&__option,
	&__target {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		padding: 16px;
		border-radius: 18px;
        cursor: pointer;
        max-width: 360px;
        margin: 4px;
        height: 120px;
        max-height: 120px;

        @media only screen and (orientation: landscape) and (min-width: 1120px) {
            width: 360px;
            flex: 1 1 1px;
            height: 160px;
            max-height: 160px
        }

	}
	&__option {
		background: $white;
		color: $black;
		font-weight: bold;
		font-size: 26px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
        z-index: 1;
        flex-direction: column;
        // text-align: left;
        transition: all 150ms;

		&:hover,
		&--selected,
		&.choice__option--default {
			color: $blue;
		}
		&--dragging {
            z-index: 2;
            box-shadow: 1px 3px 24px $black;
        }
        &--discarded {
            background: grey;
            &:hover {
                // color: $white;
                background: $white;
            }
        }
	}
	&__target {
		color: $white;
		background: rgba(50, 50, 50, 0.8);
		border: 2px dashed $white;
		z-index: 0;
        white-space: pre-wrap;
        transform: perspective(600px);
		transition: transform 300ms;

		&--hover {
			transform: scale(1.08) perspective(600px);
		}
	}
}
