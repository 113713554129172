@import '../app/vars.scss';

.chains {
	// width: 340px;
	position: relative;

	&__chainsfg {
		overflow: hidden;
		svg {
			fill: $blue;
			// width: 340px;
			height: 100%;
		}
	}
	&__chainsbg,
	&__chainsfg {
		svg {
			stroke: $black;
			stroke-width: 8px;
		}
	}
	&__chainsbg svg {
		fill: $white;
	}
	&__chapters {
		cursor: pointer;
	}
	&__step,
	&__confirm {
		position: absolute;
		bottom: 100%;
        transform: translateX(-50%);
	}
    
	&__step {
        color: $white;
		&:after {
			position: absolute;
			left: 50%;
			top: 100%;
			content: '';
			width: 0;
			height: 20px;
			border-left: 2px solid $white;
		}
	}

	&__confirm {
		background: $white;
		padding: 12px 24px;
		white-space: pre;
        cursor: pointer;
        color: $black;
	}

	&__chapters {
	}
}
