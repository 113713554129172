@import '../app/vars.scss';

.tile {
	display: inline-block;
	position: relative;
	padding: 0 8px 8px;
	cursor: pointer;
	width: 50%;
	user-select: none;

	@media only screen and (min-width: 768px) and (orientation: landscape) {
		// display: inline-flex;
		flex: 1 0 50%;
		max-height: 50%;
		width: auto;
	}

	&--selected {
		z-index: 1;
		.tile__video {
			box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.8);
		}
		.tile__intro-wrapper {
			opacity: 1;
		}
		.tile__narator {
			opacity: 0;
		}
	}

	&--unselected {
		.tile__greyed {
			opacity: 1;
		}
	}
	&__intro-wrapper {
		position: absolute;
		top: 0;
		left: 8px;
		right: 8px;
		bottom: 16px;
		opacity: 0;
		transition: opacity 300ms;
	}
	&__video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
    &__thumbnail {
        width: 100%;
        overflow-x: hidden;
        position: relative;
        flex: 1 1 1px;
    }
	&__mugshot {
        width: 10px;
        min-width: 150px;
	}
    &__greyed {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 150px;
        background: grey;
        opacity: 0;
        transition: opacity 300ms;
    }
	&__square {
		height: 0;
		padding-top: 100%;
	}
	&__begin,
	&__narator {
		display: inline-block;
		// background: $red;
		text-transform: uppercase;
		font-weight: bold;
		transition: opacity 300ms;
		white-space: nowrap; //necessary to avoid disappearing under video element

		fill: $red;
	}
	&__narator {
		padding: 8px 0 16px;
		background: white;
	}
	&__begin {
		padding: 16px;
		color: $white;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
	}
	&__label {
		width: 148px;
		height: 44px;
		margin: 0 0 -26px 10px;
	}
}
