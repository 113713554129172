@import '../app/vars.scss';

.story-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    white-space: normal;

    &__group {
    }
    &__timer {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
    }
    &__link {
        display: inline-block;
        margin: 12px;
        padding: 20px;
        background: $white;
        box-shadow: 2px 1px 6px solid $black;
        cursor: pointer;
        font-size: 18px;
        max-width: 200px;
        &:hover {
            color: $red;
        }
        
        &--default {
            font-weight: bold;
            font-size: 22px;
        }
    }
}
