$blue: #428BC4;
$yellow: #FAB95B;
$red: #F5564E;
$bg: #FEF6DF;
//$black: #303030;
$black: #0D0F15;
$white: #FFFDF8;

$sizeTitle: 28px;
$headerHeight: 80px;
