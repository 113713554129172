@import '../app/vars';

.app .app-content .story-choice {
	display: flex;
	flex: 1 1 0;
	height: 100%;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	color: $black;
	background: $white;
	padding-top: $headerHeight;
	user-select: none;
    
	@media only screen and (min-width: 768px) and (orientation: landscape) {
        align-items: center;
        width: auto;
        display: inline-flex;
        left: auto;
        margin: 0 auto;
        position: relative;
	}

	&__subtitle {
		flex: 0 1 10px;
		margin: 4px 30px 6px;
		white-space: pre-wrap;
		@media only screen and (min-width: 768px) {
			margin: 12px 30px 18px;
		}
	}
	&__stories {
		flex: 1 1 1px;
		margin: 0 8px;
		@media only screen and (min-width: 768px) and (orientation: landscape) {
			// display: flex;
			// flex-flow: column wrap;
		}
	}

	.share {
		flex: 0 0 40px;
	}
}
