@import '../app/vars.scss';

.reflection {
	position: absolute;

	&.reflection--maximized {
		.reflection {
			&__lightbox {
				pointer-events: unset;
			}
			&__play {
				$size: 140px;
				width: $size;
				height: $size;
				margin: -70px 0 0 -100px;
			}
		}
	}
	.reflection__lightbox {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $black;
		pointer-events: none;
	}
	&__thumbnail,
	&__large {
		position: absolute;
		top: 2.9%;
		left: 3.8%;
		bottom: 3%;
		right: 17.8%;
		object-fit: cover;
		object-position: center;
		overflow: hidden;
	}
	&__thumbnail {
		transition: opacity 0.6s;
	}
	&__background {
		height: 100%;
		pointer-events: none;
		fill: $white;
	}
	&__video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__progress {
		position: absolute;
		top: 8px;
		right: 8px;
    }
    @media screen and (orientation: landscape) {
        .reflection__phone {
            .subtitle {
                left: 4%;
                width: 74%;
                margin: 0 10px;
            }
        }
    }
	&__play {
		$size: 60px;
		width: $size;
		height: $size;
		margin: -30px 0 0 -40px;
		display: inline-block;

		position: absolute;
		top: 50%;
		left: 50%;

		fill: $white;
		stroke: $blue;
		stroke-width: 12px;

		$time: 0.6s;
		transition: margin $time, width $time, height $time;
	}
	&__close {
		position: absolute;
		top: -44px;
		width: 40px;
		height: 40px;
		fill: $white;
		&:hover {
			fill: $blue;
		}
	}

	&__share {
		position: fixed;
		bottom: 8px;
		left: 0;
		right: 0;
		color: $white;
	}

	&__info {
		position: absolute;
		left: -220px;
		width: 180px;
		top: 20px;

		background: white;
		color: black;
		padding: 12px;
		border-radius: 8px;
	}
}
